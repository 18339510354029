.section-redirection {
  background-color: #2a466b;
  padding: 1%;
  text-align: center;
}

.button-section {
  color: white;
  padding: 1% 2%;
}

.concept {
  color: white;
  background-color: #22272e;
  padding: 0%;
}

.fonctionalites {
  color: white;
  background-color: #fdaa41;
}

.equipe {
  background-color: white;
}

.roadmap {
  color: white;
  background-color: #2a466b;
}

.section {
  justify-content: none;
}

.column-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20%;
}

.column-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.column-first {
  display: flex;
  flex-direction: column;
  margin-top: 0%;
  margin-bottom: 3%;
  margin-left: 0%;
  margin-right: 0%;
}

.column-logo1 {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0%;
  margin-bottom: 3%;
  margin-left: 20%;
  margin-right: 1%;
  padding: 0%;
}

.column-second {
  display: flex;
  flex-direction: column;
  margin-top: 0%;
  margin-bottom: 1%;
  margin-left: 0%;
  margin-right: 1%;
}

.column-logo2 {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0%;
  margin-bottom: 3%;
  margin-left: 0%;
  margin-right: 1%;
  padding: 0%;
}

.column-third {
  display: flex;
  flex-direction: column;
  margin-top: 0%;
  margin-bottom: 3%;
  margin-left: 0%;
  margin-right: 20%;
}

.column-logo3 {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0%;
  margin-bottom: 3%;
  margin-left: 0%;
  margin-right: 1%;
  padding: 0%;
}

.subtitle {
  font-weight: bold;
  margin: 0%;
}

.title-fonctionnalites {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3%;
  margin-left: 20%;
  margin-right: 20%;
  text-align: center;
}

.container-fonctionalite {
  display: flex;
}

@media (max-width: 768px) {
  .column-left {
    margin: 0;
    text-align: center;
  }
  .logo-concept {
    width: 75%;
  }
}

@media (max-width: 768px) {
  .container-fonctionalite {
    display: contents;
  }
}
