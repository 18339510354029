.title-roadmap {
  display: flex;
  flex-direction: column;
  margin-left: 8%;
  text-align: center;
}

.description-left {
  text-align: right;
  color: #cac9c9;
}

.description-right {
  text-align: left;
  color: #cac9c9;
}
