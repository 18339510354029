.error-message {
    color: #b50d57;
    background-color: #fcf4ff;
    padding: 1% 1% 1% 1%;
    margin-top: 1%;
    display: inline-block;
    margin-bottom: 1%;
    border: solid 0.5px #b50d57;
}

.sucess-message {
    color: #008000;
    background-color: #d7ecd7;
    padding: 1% 1% 1% 1%;
    margin-top: 1%;
    display: inline-block;
    margin-bottom: 1%;
    border: solid 0.5px #008000;
}