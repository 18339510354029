.topBar {
  display: 'flex';
  justify-content: 'space-between';
  margin-top: '0vh';
  padding: '3%';
}

a {
  text-decoration: none;
  color: white;
  transition: 0.2s;
}
a:hover {
  text-decoration: none;
  color: #fdaa41;
  transform: scale(1.02);
}
div > a {
  margin-right: 2vw;
  font-weight: bold;
}

.Vid {
  cursor: pointer;
  transition: 0.6s;
  border-radius: 20px;
}
.Vid:hover {
  transform: scale(0.9);
}
.underLine {
  transition: 10s;
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.underLine1 {
  color: rgb(112, 108, 108);
  transition: 10s;
  display: inline-block;
  position: relative;
}
.underLine:after,
.underLine1:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #646464;
  transform-origin: bottom right;
  transition: transform 0.75s ease-in-out;
}
.underLine:hover:after {
  transform: scaleX(1);
}
.underLine1:hover:after {
  transform: scaleX(0.9);
  transform-origin: bottom left;
}
.underLine2 {
  color: rgb(112, 108, 108);
  transition: 10s;
  display: inline-block;
  position: relative;
}
.underLine2:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -10px;
  left: 0;
  background-color: #fdaa41;
  transform-origin: bottom right;
  transition: transform 0.25s ease-in-out;
}
.underLine2:hover:after {
  transform: scaleX(0.9);
  transform-origin: bottom left;
}

li {
  list-style-type: none;
  margin-right: 2vw;
  cursor: pointer;
  text-align: center;
}

ul li {
  margin-bottom: 1vh;
  text-align: center;
}

@media only screen and (max-width: 1024px) {
  .Top_Area_Responsive {
    display: flex;
    flex-wrap: wrap;
  }
  .Top_Area_Text_Left_Responsive {
    justify-content: center;
    text-align: center;
    font-size: 2rem;
    margin-bottom: 5vh;
    margin-left: 25vw;
    display: flex;
    flex-wrap: wrap;
  }
}

a:active {
  color: #bd8031;
}
