.btn-slide {
    position: relative;
    display: inline-block;
    height: 50px;
    width: 200px;
    line-height: 50px;
    padding: 0;
    border-radius: 50px;
    background: #22272E;
    border: 2px solid #fdaa41;
    margin: 0px;
    transition: .5s;
    color: #fdaa41;
}

.btn-slide:hover {
    background-color: #fdaa41;
}

.btn-slide:hover span.circle {
    left: 100%;
    margin-left: -45px;
    background-color: #22272E;
    color: #fdaa41;
}


.btn-slide:hover span.title2 {
    left: 40px;
    opacity: 0;
}

.btn-slide:hover span.title-hover2 {
    opacity: 1;
    left: 40px;
}

.btn-slide span.circle {
    display: block;
    background-color: #fdaa41;
    color: #fdaa41;
    position: absolute;
    float: left;
    margin: 5px;
    line-height: 42px;
    height: 40px;
    width: 40px;
    top: 0;
    left: 0;
    transition: .5s;
    border-radius: 50%;
}


.btn-slide span.title2 {
    position: absolute;
    left: 80px;
    text-align: center;
    margin: 0 auto;
    font-size: 16px;
    font-weight: bold;
    color: #fdaa41;
    transition: .5s;

}

.btn-slide span.title-hover2 {
    position: absolute;
    left: 80px;
    text-align: center;
    margin: 0 auto;
    font-size: 16px;
    font-weight: bold;
    color: #22272E;
    transition: .5s;
    opacity: 0;

}
