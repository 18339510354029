.title {
  background-color: #bd8031;
  padding-right: 75%;
  padding-left: 2%;
  padding-top: 1%;
  padding-bottom: 1%;
  box-shadow: 3px 3px 3px #5f4119;
  display: inline;
}

.banner-text {
  display: flex;
  margin-top: -2%;
  justify-content: center;
  align-items: center;
  margin-bottom: 2%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.custom-element {
  width: 96%;
  height: 0px;
  border: 1px solid #c6c6c6;
  margin-left: 2%;
}

.faq-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.faq-item {
  margin-bottom: 1%;
  width: 80%;
}

.toggle-icon {
  font-size: 18px; /* Adjust font size of the icon */
  cursor: pointer; /* Add pointer cursor for clickability */
  font-weight: 600;
  float: right;
}

.faq-question {
  flex: 1;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 0%;
  margin-bottom: 0%;
  /* Question ? */
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 17px;

  color: #000000;
  cursor: default;
  padding-top: 1%;
  padding-bottom: 1%;
}

.faq-answer {
  margin-left: 4%;
  margin-right: 4%;
  margin-top: 0%;
  margin-bottom: 1%;
}

.faq-answer.show {
  max-height: 500px;
  /* Adjust as needed */
}

.slide-up,
.slide-down {
  overflow: hidden;
}

.slide-up > div,
.slide-down > div {
  margin-top: -25%;
  transition: margin-top 0.4s ease-in-out;
}

.slide-down > div {
  margin-top: 0;
}
