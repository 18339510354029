.container {
  display: flex;
  align-items: center;
  margin-bottom: 3%;
}

.column {
  flex: 1;
  margin: 0%;
  padding: 0%;
  /* Espace entre les colonnes */
}

.photo-round {
  width: 250px;
  height: auto;
  background-color: #ccc;
  /* Remplacez par la couleur ou l'image souhaitée */
  border-radius: 50%;
}

.subtext {
  padding: 0%;
  margin-bottom: 0%;
  margin-left: 0%;
  margin-right: 0%;
  margin-top: 0.5%;
}

.linkedin {
  margin-top: 10px;
}

a.linkedin-link {
  display: inline-block; /* Permet d'appliquer le padding */
  text-decoration: none; /* Supprime le soulignement du lien */
  transition: background-color 0.3s; /* Animation lors du survol */
}

a.linkedin-link:hover {
  background-color: white; /* Change la couleur de fond lors du survol */
}


@media (max-width: 768px) {
  .container {
    display: contents;
  }
}
