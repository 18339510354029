.input-style {
    width: 50%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    float: left;
}

.select-style {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.textarea-style {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    height: 150px;
}

.submit-style {
    float: right;
    margin-top: 2%;
    margin-bottom: 3%;
    background-color: #FDAA41;
    color: white;
    border-radius: 50px;
    width: 12%;
    height: 10%;
    font-size: 16px;
    border: 0px;
}

.submit-style:hover {
    background-color: #bd8031; 
}


.submit-style:active {
    background-color: #bd8031; 
    color: rgb(199, 198, 198);
}

.explication {
    background-color: #FDAA41;
    color: white;
    align-items: center;
    text-align: center;
    margin-top: 3%;
    margin-bottom: 3%;
    margin-left: 7%;
    margin-right: 7%;
    border-radius: 15px;
    padding: 1% 5%;
    font-weight: bold;
}

.title-testeur{
    align-items: center;
    text-align: center;
}

.download-container {
    align-items: center;
    text-align: center;
    margin: 1%;
}

.input-style-dt {
    width: 30%;
    padding: 12px 20px;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}


.input-email {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.container-input {
    display: flex;
    justify-content: space-between;
}

.button-download {
  font-size: 16px;
  font-weight: 200;
  letter-spacing: 1px;
  padding: 13px 20px 13px;
  outline: 0;
  border: 1px solid black;
  cursor: pointer;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  color: white;
  font-weight: bold;
}

.button-download:after {
  content: "";
  background-color: #FDAA41;
  width: 100%;
  z-index: -1;
  position: absolute;
  height: 100%;
  top: 7px;
  left: 7px;
  transition: 0.2s;
}

.button-download:hover:after {
  top: 0px;
  left: 0px;
}

@media (min-width: 768px) {
  .button-download {
    padding: 13px 50px 13px;
  }
}